import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import AudioPlayer from "../../../components/audio-player"
import Breadcrumb from "../../../components/breadcrumb"
import Caption from "../../../components/caption"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Image from "../../../components/image"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

import LeichteSpracheNext from "../../../content/shared/leichte-sprache-next"

import VectorScardanelli from "../../../images/dauerausstellung/05-haelfte-des-lebens/scardanelli.svg"
import VectorDatum from "../../../images/leichte-sprache/datum.svg"

const LeichteSpracheTurm = () => {
  const data = useStaticQuery(graphql`
    query LeichteSpracheTurmIndexQuery {
      turmFrueher: file(
        relativePath: { eq: "leichte-sprache/turm-frueher.jpg" }
      ) {
        ...mediumImage
      }
      turmHeute: file(relativePath: { eq: "leichte-sprache/turm-heute.jpg" }) {
        ...mediumImage
      }
      tisch: file(relativePath: { eq: "leichte-sprache/tisch.jpg" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Hölderlins Zeit im Tübinger Turm"
        description="In Tübingen wohnt Hölderlin bei Familie Zimmer. Familie Zimmer wohnt in einem Haus mit Turm. Das Haus steht direkt am Neckar. Hölderlin wohnt in einem Zimmer im 1. Stock."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Über Hölderlin",
                link: "/leichte-sprache/ueber-hoelderlin",
              },
              {
                title: "Turm",
                link: "/leichte-sprache/ueber-hoelderlin/turm",
              },
            ]}
          />
          <PageTitle>Hölderlins Zeit im Tübinger Turm</PageTitle>
          <Constrain align="left">
            <AudioPlayer src="leichte-sprache/09-turm" />
          </Constrain>
          <Paragraph dropcap={true}>
            In Tübingen wohnt Hölderlin bei Familie Zimmer.
            <br />
            Familie Zimmer wohnt in einem Haus mit Turm.
            <br />
            Das Haus steht direkt am Neckar. <br />
            Hölderlin wohnt in einem Zimmer im 1. Stock.
          </Paragraph>
          <Grid columns={[1, 2]} space={[6, 3]}>
            <Image
              image={data.turmFrueher.childImageSharp.gatsbyImageData}
              alt="Ein altes Foto vom Gebäude. Der Turm hat darauf noch kein Spitz-Dach."
              caption="So sah der Turm früher aus"
              attribution="Stadtarchiv Tübingen"
            />
            <Image
              image={data.turmHeute.childImageSharp.gatsbyImageData}
              alt="Ein neues Foto vom Gebäude. Der Turm hat jetzt ein Spitz-Dach."
              attribution="Gudrun de Maddalena"
              caption="So sieht der Turm heute aus"
            />
          </Grid>
        </Stack>

        <Stack>
          <Constrain>
            <Paragraph>
              Hölderlin schreibt auch im Turm noch viele Gedichte. <br />
              Aber er schreibt jetzt andere Gedichte. <br />
              Früher hat er oft über die Frei∙heit geschrieben. <br />
              Die Gedichte, die er im Turm schreibt, <br />
              handeln oft von den Jahres∙zeiten. <br />
              Viele Gedichte erzählen, <br />
              was Hölderlin vor seinem Fenster sieht.
            </Paragraph>
          </Constrain>
          <Constrain align="right">
            <Image
              image={data.tisch.childImageSharp.gatsbyImageData}
              alt="Ein heller Raum mit einem alten Holzboden und einem kleinen Schreibtisch in der Mitte."
              attribution="David Franck"
              caption="So sieht Hölderlins Zimmer heute aus."
            />
          </Constrain>
          <Constrain>
            <Paragraph>
              Wenn Hölderlin Besuch bekommt, <br />
              macht er mit seinem Besuch oft einen Spaziergang. <br />
              Manchmal kann er aber nicht raus gehen. <br />
              Dann schaut Hölderlin gerne zum Fenster hinaus. <br />
              Wenn das Wetter gut ist, <br />
              geht es Hölderlin besser. <br />
              Wenn das Wetter schlecht ist, <br />
              geht es ihm nicht so gut.
            </Paragraph>
          </Constrain>
        </Stack>

        <Stack>
          <Stack space={6}>
            <img
              src={VectorScardanelli}
              alt="Das Pesudonym Scardanelli in Hölderlins Hand∙schrift"
              width="1000"
              height="215"
            />
            <Caption>Der Name Scardanelli in Hölderlins Hand∙schrift</Caption>
          </Stack>
          <Constrain>
            <Paragraph>
              Hölderlin unterschreibt seine Gedichte <br />
              jetzt oft nicht mehr mit seinem Namen. <br />
              Unter die Gedichte schreibt er jetzt oft diesen Namen: <br />
              Scardanelli. <br />
              Man weiß nicht: <br />
              Macht er das absichtlich? <br />
              Oder glaubt er wirklich, <br />
              dass er Scardanelli heißt?
            </Paragraph>
          </Constrain>
        </Stack>

        <Stack>
          <Stack space={6}>
            <img
              src={VectorDatum}
              alt="den 24ten März 1871 in Hölderlins Hand∙schrift"
              width="1000"
              height="215"
            />
            <Caption>»den 24ten März 1871« in Hölderlins Hand∙schrift</Caption>
          </Stack>
          <Constrain>
            <Paragraph>
              Und er schreibt oft ein falsches Datum unter die Gedichte. <br />
              Deshalb weiß man bei vielen Gedichten nicht: <br />
              Wann hat Hölderlin sie wirklich geschrieben? <br />
              Man weiß auch nicht: <br />
              Macht er das absichtlich? <br />
              Oder weiß er wirklich nicht, <br />
              welcher Tag und welches Jahr ist?
            </Paragraph>
          </Constrain>
        </Stack>

        <Constrain>
          <Paragraph>
            Hölderlin lebt im Turm, bis er stirbt. <br />
            Da ist er 73 Jahre alt.
          </Paragraph>
        </Constrain>
        <LeichteSpracheNext variant="aussicht" />
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheTurm
