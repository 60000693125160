import React from "react"

import Constrain from "../../components/constrain"
import Stack from "../../components/stack"
import Button from "../../components/button"
import Heading from "../../components/heading"

import HyperAussicht from "./hyper-aussicht"
import HyperBibliothekar from "./hyper-bibliothekar"
import HyperFamilie from "./hyper-familie"
import HyperFreunde from "./hyper-freunde"
import HyperKrank from "./hyper-krank"
import HyperOrte from "./hyper-orte"
import HyperPfarrer from "./hyper-pfarrer"
import HyperTurm from "./hyper-turm"
import HyperVerliebt from "./hyper-verliebt"

const LeichteSpracheNext = ({ variant }) => {
  return (
    <Constrain>
      <Stack space={12}>
        <Stack space={6}>
          <Heading as="p">Mehr über Friedrich Hölderlin:</Heading>
          {variant === "aussicht" ? (
            <HyperAussicht />
          ) : variant === "freunde" ? (
            <HyperFreunde />
          ) : variant === "familie" ? (
            <HyperFamilie />
          ) : variant === "pfarrer" ? (
            <HyperPfarrer />
          ) : variant === "verliebt" ? (
            <HyperVerliebt />
          ) : variant === "bibliothekar" ? (
            <HyperBibliothekar />
          ) : variant === "krank" ? (
            <HyperKrank />
          ) : variant === "turm" ? (
            <HyperTurm />
          ) : variant === "orte" ? (
            <HyperOrte />
          ) : null}
        </Stack>
        <Button secondary={true} to="/leichte-sprache/ueber-hoelderlin">
          Alles über Friedrich Hölderlin
        </Button>
      </Stack>
    </Constrain>
  )
}

export default LeichteSpracheNext
